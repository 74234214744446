import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import BlogCard from "../components/blogCard";
import Hero from "../components/hero";
import Layout from "../components/layout";

const CaseStudiesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: wpMediaItem(title: { eq: "Cranfield IT Response Car" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      logoImg: wpMediaItem(title: { eq: "cyberEssentials-1 1" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      allWpCaseStudy(sort: { fields: dateGmt, order: DESC }) {
        nodes {
          slug
          excerpt
          title
          dateGmt(formatString: "D MMMM yyyy")
          caseStudyFields {
            featuredImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(formats: AUTO)
                }
              }
              altText
            }
          }
        }
      }

      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const siteUrl = data.site.siteMetadata.siteUrl;
  // const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Case Studies",
        item: {
          url: `${siteUrl}/case-studies`,
          id: `${siteUrl}/case-studies`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>

      <GatsbySeo
        title="Case Studies | Cranfield IT Solutions: Computer Repair & Tech Support Sussex"
        description="Explore our collection of case studies, showcasing how our expertise has resolved IT challenges for a range of businesses. These real-world scenarios that highlight our commitment to delivering effective solutions and empowering you with unparalleled IT support knowledge."
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/case-studies`,
          title: "Case Studies | Cranfield IT Solutions: Computer Repair & Tech Support Sussex",
          description:
            "Explore our collection of case studies, showcasing how our expertise has resolved IT challenges for a range of businesses. These real-world scenarios that highlight our commitment to delivering effective solutions and empowering you with unparalleled IT support knowledge.",
          images: [
            {
              url: `${data.heroImg?.localFile.publicURL}`,
              width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
              height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
              alt: `${data.heroImg?.altText}`,
            },
          ],
        }}
      />

      <Layout>
        <Hero
          title={
            <>
              Case <span className="roboto-light">Studies</span>
            </>
          }
          url="/contact-us"
        />

        <section className="pb-5 pb-lg-7">
          <Container>
            <Row>
              <Col lg={8}>
                <h2 className="fs-1 mb-4 ">Our latest case studies</h2>
                <p className="pb-md-3">
                  Explore our collection of case studies, showcasing how our
                  expertise has resolved IT challenges for a range of
                  businesses. These real-world scenarios that highlight our
                  commitment to delivering effective solutions and empowering
                  you with unparalleled IT support knowledge.
                </p>
              </Col>
            </Row>
            <Row className="g-5 g-xl-6">
              {data.allWpCaseStudy.nodes.map((blog, index) => (
                <BlogCard
                  img={
                    blog.caseStudyFields.featuredImage.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  imgAlt={blog.caseStudyFields.featuredImage.altText}
                  title={blog.title}
                  date={blog.dateGmt}
                  url={`/case-studies/${blog.slug}`}
                />
              ))}
            </Row>
          </Container>
        </section>
      </Layout>
    </>
  );
};

export default CaseStudiesPage;
